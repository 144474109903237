import React, { useEffect, useState } from 'react';
import { ConfigProvider, Input, notification, theme } from 'antd';
import { Paths, PathsAPI } from '../../routes/paths';
import { Header } from '../../components/header';
import {
  validateEmailFormat,
  validateEmailIsUnique,
  validatePassword,
} from '../../shared/validation';
import { openNotificationWithIcon } from '../../components/notifications';
import { axiosInstance } from '../../shared/axios';
import { LOGIN } from '../../actions';
import { MainAction } from '../../typings/main_context_reducer';
import { useMainContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import { authRefresh } from '../../actions/check';
import {
  DEFAULT_ERROR_DESCRIPTION,
  DEFAULT_ERROR_MESSAGE,
} from '../../shared/constants';

export const SignUp = () => {
  const [, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const check = await authRefresh();

      if (check) {
        navigate(Paths.home);
      }
    };

    loadData();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <section className="signup outer-wrapper">
        {contextHolder}
        <Header title="Signup" />
        <div className="center-wrapper">
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2>Sign up</h2>
            </div>
            <div className="middle-wrapper">
              <Input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
              />
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Password"
              />
              <Input.Password
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                placeholder="Confirm Password"
              />
              <p className="card-default">
                By signing up, you agree to our{' '}
                <a href={Paths.terms} target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href={Paths.privacy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="bottom-wrapper">
              <button
                className="action-button submit-btn call-to-action"
                onClick={async () => {
                  const isEmailValid = validateEmailFormat(email);
                  const isEmailUnique = await validateEmailIsUnique(email);

                  if (!isEmailValid) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Invalid Email',
                      description:
                        'Your email is not valid format. Please enter a valid email address.',
                    });

                    return;
                  }

                  if (!isEmailUnique) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Invalid Email',
                      description:
                        'Your email is not unique. Please enter a different email.',
                    });

                    return;
                  }

                  if (password !== confirmPassword) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Passwords do not match',
                      description: 'Please make sure your passwords match.',
                    });

                    return;
                  }

                  if (!validatePassword(password)) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Invalid Password',
                      description:
                        'Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.',
                    });

                    return;
                  }

                  try {
                    // create prospect
                    await axiosInstance.post(PathsAPI.prospect, {
                      email,
                      password,
                    });

                    // login
                    const responseLogin = await axiosInstance.post(
                      PathsAPI.login,
                      {
                        email,
                        password,
                      },
                    );

                    // send verification email
                    await axiosInstance.post(PathsAPI.sendVerifyEmail);

                    dispatch({
                      type: LOGIN,
                      LoginAction: {
                        account: responseLogin.data.account,
                      },
                    } as MainAction);
                    navigate(Paths.home);
                  } catch (error: any) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: DEFAULT_ERROR_MESSAGE,
                      description:
                        error?.response?.data?.message ??
                        DEFAULT_ERROR_DESCRIPTION,
                    });
                  }
                }}
              >
                Submit
              </button>
              <p className="card-default">
                Already have an account? <a href={Paths.login}>Login</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
};
