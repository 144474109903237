import React, { useEffect, useState } from 'react';
import { Paths } from '../../routes/paths';
import { SUPPORT_EMAIL } from '../../shared/constants';
import { Header } from '../../components/header';

export const Terms = () => {
  return (
    <section className="legal outer-wrapper">
      <Header title="Terms of Service" />
      <div className="center-wrapper">
        <div className="inner-wrapper">
          <div className="top-wrapper">
            <h2>Terms of Service</h2>
          </div>
          <div className="middle-wrapper">
            <p className="card-default">
              <b>1. Introduction</b>
              <span>
                These Terms of Service ("Terms") govern your use of [Website
                Name] (the "Service"), a platform designed to notify you when
                animals become available for adoption, purchase, or other
                acquisition on third-party websites (the "Third-Party
                Websites"). By accessing or using the Service, you agree to be
                bound by these Terms. If you do not agree to these Terms, please
                do not use the Service.
              </span>

              <b>2. Service Description</b>
              <span>
                The Service provides a platform to monitor Third-Party Websites
                for animal availability. The Service does not sell, adopt, or
                otherwise facilitate the acquisition of animals. The Service is
                intended for informational purposes only.
              </span>

              <b>3. Third-Party Websites</b>
              <span>
                You acknowledge and agree that the Service is not responsible
                for the content, accuracy, or legality of any information or
                materials on Third-Party Websites. The Service does not endorse,
                control, or guarantee the quality, safety, or suitability of any
                animals listed on Third-Party Websites.
              </span>

              <b>4. No Liability</b>
              <span>
                The Service is provided on an "as is" and "as available" basis,
                without any warranties of any kind, either express or implied.
                The Service disclaims all warranties, including but not limited
                to, implied warranties of merchantability, fitness for a
                particular purpose, and non-infringement.{' '}
              </span>
              <span>
                The Service shall not be liable for any direct, indirect,
                incidental, special, or consequential damages arising out of or
                in connection with your use of or inability to use the Service,
                even if the Service has been advised of the possibility of such
                damages.{' '}
              </span>

              <b>5. Indemnification</b>
              <span>
                You agree to indemnify, defend, and hold harmless the Service,
                its affiliates, officers, directors, employees, and agents from
                and against any and all claims, liabilities, damages, and costs
                (including reasonable attorneys' fees) arising out of or related
                to your use of the Service, your violation of these Terms, or
                your violation of any rights of another.{' '}
              </span>

              <b>6. Governing Law</b>
              <span>
                These Terms shall be governed by and construed in accordance
                with the laws of the State of [State], without regard to its
                conflict of law provisions.
              </span>

              <b>7. Modifications</b>
              <span>
                The Service may modify these Terms from time to time. Your
                continued use of the Service after such modifications have been
                made constitutes your acceptance of the modified Terms.
              </span>

              <b>8. Contact Information</b>
              <span>
                If you have any questions about these Terms or the Service,
                please contact us at <b>{SUPPORT_EMAIL}</b>.
              </span>
            </p>
          </div>
          <div className="bottom-wrapper">
            <a href={Paths.signup} className="action-button secondary-action">
              Home
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
