import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

export interface DeleteInterestModalProps {
  id: string;
  isModalOpen: boolean;
  handleDelete: () => void;
  handleCancel: () => void;
}

export const DeleteInterestModal = (props: DeleteInterestModalProps) => {
  const { isModalOpen, handleDelete, handleCancel, id } = props;
  const navigate = useNavigate();

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      className="delete-account-modal"
    >
      <h5>Are you sure you want to delete your account?</h5>
      <div className="btn-wrapper">
        <button
          className="action-button submit-btn delete-action"
          onClick={handleDelete}
        >
          Delete Interest
        </button>
        <button
          className="action-button secondary-action"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
