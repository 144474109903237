/* istanbul ignore file */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Paths } from './paths';
import { ContextWrapper } from '../components/context_wrapper';

// Pages
import { Home } from '../pages/home';
import { Login } from '../pages/auth/login';
import { SignUp } from '../pages/auth/signup';
import { ForgotPassword } from '../pages/auth/forgot_password';
import { ResetPassword } from '../pages/auth/reset_password';
import { VerifyEmail } from '../pages/auth/verify_email';
import { Setting } from '../pages/setting';
import { Landing } from '../pages/landing';
import { Terms } from '../pages/legal/terms';
import { Privacy } from '../pages/legal/privacy';

export const AppRoutes = (): JSX.Element => {
  return (
    <div>
      <ContextWrapper>
        <Routes>
          {/* Auth */}
          <Route path={Paths.login} Component={Login} />
          <Route path={Paths.signup} Component={SignUp} />
          <Route path={Paths.forgotPassword} Component={ForgotPassword} />
          <Route path={Paths.passwordReset} Component={ResetPassword} />
          <Route path={Paths.verifyEmail} Component={VerifyEmail} />

          {/* Legal */}
          <Route path={Paths.terms} Component={Terms} />
          <Route path={Paths.privacy} Component={Privacy} />

          {/* Base */}
          <Route path={Paths.landing} Component={Landing} />
          <Route path={Paths.home} Component={Home} />
          <Route path={Paths.settings} Component={Setting} />
        </Routes>
      </ContextWrapper>
    </div>
  );
};
