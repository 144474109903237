import {
  SIGN_UP,
  LOGIN,
  CHECK,
  GET_SITES,
  GET_INTERESTS,
  GET_SPECIMENS,
} from '../actions';
import { MainState, MainAction } from '../typings/main_context_reducer';

export const MainReducer = (
  state: MainState,
  action: MainAction,
): MainState => {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        ...(action.signupAction ?? {}),
      };
    case LOGIN:
      return {
        ...state,
        ...(action.loginAction ?? {}),
      };
    case CHECK:
      return {
        ...state,
        ...(action.checkAction ?? {}),
      };
    case GET_SITES:
      return {
        ...state,
        ...(action.siteAction ?? {}),
      };
    case GET_INTERESTS:
      return {
        ...state,
        ...(action.interestAction ?? {}),
      };
    case GET_SPECIMENS:
      return {
        ...state,
        ...(action.specimenAction ?? {}),
      };
    default:
      throw new Error();
  }
};
