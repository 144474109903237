import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';
import { formateDateStandard } from '../utils/dates';
import { DeleteInterestModal } from './modals/delete_interest';

export const InterestCard = (props: {
  name: string;
  id: string;
  handleClick: () => void;
  handleDelete: () => void;
}) => {
  const { id, name, handleDelete } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <li className="card-default interest-card">
        <div className="row">
          <div onClick={props.handleClick}>
            <span>Name</span>
            <p>{name}</p>
          </div>
          <button className="delete-btn" onClick={() => setIsModalOpen(true)}>
            <DeleteIcon className="delete-icon icon" />
          </button>
        </div>
      </li>

      <DeleteInterestModal
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
        handleDelete={async () => {
          await handleDelete();
          setIsModalOpen(false);
        }}
        id={id}
      />
    </>
  );
};

export const MatchesCard = (props: {
  name: string;
  discoveredOn: string;
  siteName: string;
  url: string;
  imageSrc?: string | null;
  regularPrice?: string | null;
  salesPrice?: string | null;
}) => {
  const {
    name,
    discoveredOn,
    siteName,
    url,
    imageSrc,
    regularPrice,
    salesPrice,
  } = props;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <li className="card-default match-card">
        <div className="row">
          <div>
            <span>Name</span>
            <p>{name}</p>
          </div>
        </div>
        {imageSrc && (
          <div className="row">
            <img src={imageSrc} alt={name} />
          </div>
        )}
        <div className="row">
          <div>
            <span>Site</span>
            <p>{siteName}</p>
          </div>

          <div>
            <span>Discovered On</span>
            <p>{formateDateStandard(discoveredOn)}</p>
          </div>
        </div>
        <div className="row">
          <div>
            <span>Regular Price</span>
            <p>{regularPrice ?? 'N/A'}</p>
          </div>

          <div>
            <span>Sales Price</span>
            <p>{salesPrice ?? 'N/A'}</p>
          </div>
        </div>
      </li>
    </a>
  );
};
