import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { deleteProspect } from '../../actions/propspect';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../routes/paths';

export interface DeleteAccountModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
}

export const DeleteAccountModal = (props: DeleteAccountModalProps) => {
  const { isModalOpen, handleCancel } = props;
  const navigate = useNavigate();

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      className="delete-account-modal"
    >
      <h5>Are you sure you want to delete your account?</h5>
      <div className="btn-wrapper">
        <button
          className="action-button submit-btn delete-action"
          onClick={async () => {
            deleteProspect();
            navigate(Paths.login);
          }}
        >
          Delete Account
        </button>
        <button
          className="action-button secondary-action"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
