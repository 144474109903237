import React, { useEffect, useState } from 'react';
import { Select, Input, notification, ConfigProvider, theme } from 'antd';
import { DeleteAccountModal } from '../components/modals/delete_account';
import { useMainContext } from '../context/context';
import { authRefresh } from '../actions/check';
import { Paths } from '../routes/paths';
import { useNavigate } from 'react-router-dom';
import { CHECK } from '../actions';
import { MainAction } from '../typings/main_context_reducer';
import { Header } from '../components/header';
import { changePassword, updateProspect } from '../actions/propspect';
import { updateSetting } from '../actions/setting';
import { openNotificationWithIcon } from '../components/notifications';
import { DEFAULT_ERROR_DESCRIPTION } from '../shared/constants';
import { ChangePasswordModal } from '../components/modals/change-password';
import { sendVerifyEmail } from '../actions/auth';

export const Setting = () => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [email, setEmail] = useState('');
  const [notificationsOptions, setNotificationsOptions] = useState<
    { value: string; label: string }[]
  >([{ value: 'email', label: 'Email' }]);
  const [selectedNotification, setSelectedNotification] = useState(
    notificationsOptions[0].value as 'email' | 'none',
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();

        if (check === undefined) {
          navigate(Paths.login);
        }

        if (!check?.prospect.is_verified) {
          openNotificationWithIcon({
            api,
            type: 'info',
            message: 'Email verification',
            description:
              'Please check your email. A verification link has been sent to you.',
          });
        }

        const checkAction = {
          type: CHECK,
          checkAction: check,
        } as MainAction;

        dispatch(checkAction);
        setEmail(check?.prospect.email ?? '');
        setSelectedNotification(check?.setting.notification ?? 'email');
      } catch (error) {
        console.error(error);
      }
    };

    loadData();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <section className="settings outer-wrapper">
        {contextHolder}
        <Header title="Settings" />
        <div className="center-wrapper">
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2>Settings</h2>
            </div>
            <div className="middle-wrapper">
              <div className="input-wrapper">
                <label>Email</label>
                <Input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="input-wrapper">
                <label>Notification Type</label>
                <Select
                  defaultValue={selectedNotification}
                  onChange={(value) => {
                    setSelectedNotification(value);
                  }}
                  options={notificationsOptions}
                />
              </div>
              <div className="input-wrapper">
                <button
                  className="action-button default-action"
                  onClick={() => setIsChangePasswordModalOpen(true)}
                >
                  Change Password
                </button>
              </div>
              {!mainState?.prospect?.is_verified && (
                <div className="input-wrapper">
                  <button
                    className="action-button default-action"
                    onClick={async () => {
                      try {
                        await sendVerifyEmail();

                        openNotificationWithIcon({
                          api,
                          type: 'success',
                          message: 'Sent',
                          description:
                            'Please check your email. A verification link has been sent to you.',
                        });
                      } catch (error) {
                        openNotificationWithIcon({
                          api,
                          type: 'info',
                          message: 'Email verification',
                          description:
                            'Sorry, we could not send a verification email. Please try again later.',
                        });
                      }
                    }}
                  >
                    Resend Email Verification
                  </button>
                </div>
              )}
            </div>
            <div className="bottom-wrapper">
              <button
                className="action-button submit-btn call-to-action"
                onClick={async () => {
                  try {
                    if (mainState?.prospect.email !== email) {
                      await updateProspect({ email });
                    }

                    if (
                      mainState?.setting.notification !== selectedNotification
                    ) {
                      await updateSetting({
                        id: mainState?.setting.id,
                        notification: selectedNotification,
                      });
                    }

                    openNotificationWithIcon({
                      api,
                      type: 'success',
                      message: 'Successfully Updated',
                      description:
                        'Your settings have been successfully updated.',
                    });
                  } catch (error: any) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Error',
                      description:
                        error?.response?.data?.message ??
                        DEFAULT_ERROR_DESCRIPTION,
                    });
                  }
                }}
              >
                Save
              </button>
              <button
                className="action-button delete-action opacity-toggle"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>

        <DeleteAccountModal
          isModalOpen={isDeleteModalOpen}
          handleCancel={() => setIsDeleteModalOpen(false)}
        />
        <ChangePasswordModal
          isModalOpen={isChangePasswordModalOpen}
          handleCancel={() => setIsChangePasswordModalOpen(false)}
        />
      </section>
    </ConfigProvider>
  );
};
