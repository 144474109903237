import React, { useState } from 'react';
import { Button, Input, Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from '../../context/context';
import { changePassword } from '../../actions/propspect';
import { openNotificationWithIcon } from '../notifications';
import { DEFAULT_ERROR_DESCRIPTION } from '../../shared/constants';
import { validatePassword } from '../../shared/validation';

export interface ChangePasswordModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
}

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const { isModalOpen, handleCancel } = props;
  const navigate = useNavigate();
  const [mainState, dispatch] = useMainContext();
  const [api, contextHolder] = notification.useNotification();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      className="change-password-modal"
    >
      <div className="middle-wrapper">
        <div className="input-wrapper">
          <label>Current Password</label>
          <Input.Password
            onChange={(e) => setCurrentPassword(e.target.value)}
            value={currentPassword}
          />
        </div>
        <div className="input-wrapper">
          <label>New Password</label>
          <Input.Password
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
        </div>
        <div className="input-wrapper">
          <label>Confirm New Password</label>
          <Input.Password
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            value={confirmNewPassword}
          />
        </div>
      </div>
      <div className="btn-wrapper">
        <div className="bottom-wrapper">
          <button
            className="action-button submit-btn call-to-action"
            onClick={async () => {
              try {
                if (newPassword.length === 0) {
                  openNotificationWithIcon({
                    api,
                    type: 'error',
                    message: 'No new password',
                    description: 'Your new password cannot be empty.',
                  });

                  return;
                }

                if (!validatePassword(newPassword)) {
                  openNotificationWithIcon({
                    api,
                    type: 'error',
                    message: 'Invalid Password',
                    description:
                      'Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.',
                  });

                  return;
                }

                if (newPassword !== confirmNewPassword) {
                  openNotificationWithIcon({
                    api,
                    type: 'error',
                    message: 'Passwords do not match',
                    description: 'Please make sure your passwords match.',
                  });

                  return;
                }

                if (newPassword === currentPassword) {
                  openNotificationWithIcon({
                    api,
                    type: 'error',
                    message: 'Old/New Passwords match',
                    description:
                      'Your old password and new password are the same.',
                  });

                  return;
                }

                await changePassword({
                  newPassword,
                  currentPassword,
                });

                handleCancel();
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                openNotificationWithIcon({
                  api,
                  type: 'success',
                  message: 'Successfully Updated',
                  description: 'Your password have been successfully updated.',
                });
              } catch (error: any) {
                openNotificationWithIcon({
                  api,
                  type: 'error',
                  message: 'Error',
                  description:
                    error?.response?.data?.message ?? DEFAULT_ERROR_DESCRIPTION,
                });
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
