import React, { useReducer } from 'react';
import { MainContext } from '../context/context';
import { MainReducer } from '../reducers/main_reducer';
import { initialMainState } from '../states/initial_main_state';
import { ContextWrapperProps } from '../typings/main_context_reducer';

export const ContextWrapper = (props: ContextWrapperProps): JSX.Element => {
  const [state, dispatch] = useReducer(MainReducer, initialMainState());

  return (
    <MainContext.Provider value={[state, dispatch]}>
      {props.children}
    </MainContext.Provider>
  );
};
