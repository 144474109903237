import { PathsAPI } from '../routes/paths';
import { axiosInstance } from './axios';

export const validateEmailFormat = (email: string): boolean => {
  const matches = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  return matches !== null;
};

export const validateEmailIsUnique = async (
  email: string,
): Promise<boolean> => {
  const response = await axiosInstance.get(PathsAPI.verifyEmailIsAvailable, {
    params: {
      email,
    },
  });

  return response.data;
};

export const validatePassword = (password: string): boolean => {
  const matches = String(password).match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  return matches !== null;
};

export const validateInterestName = (name: string): boolean => {
  const maxNameLength = 100;

  if (!name || name.length <= 3 || name.length >= maxNameLength) {
    return false;
  }

  const matches = name.match(/^[\w\-\s]+$/);

  return !!matches;
};
