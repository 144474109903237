import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { MenuProps } from 'antd';
import { Dropdown, Tag } from 'antd';

import { BRAND_NAME } from '../shared/constants';
import { useMainContext } from '../context/context';
import { axiosInstance } from '../shared/axios';
import { Paths, PathsAPI } from '../routes/paths';
import { ReactComponent as LogoIcon } from '../icons/logo.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as SettingIcon } from '../icons/setting.svg';
import { ReactComponent as HomeIcon } from '../icons/home.svg';
import PackageJSON from '../../package.json';

export const Header = (props: { title: string }): JSX.Element => {
  const [mainState] = useMainContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname.includes(Paths.login);

  const items: MenuProps['items'] = [
    {
      key: 'home',
      label: (
        <a className="dropdown-item" href={Paths.home}>
          <HomeIcon className="setting-icon icon" />
          <span>Home</span>
        </a>
      ),
    },
    {
      key: 'settings',
      label: (
        <a className="dropdown-item" href={Paths.settings}>
          <SettingIcon className="setting-icon icon" />
          <span>Settings</span>
        </a>
      ),
    },
    {
      key: 'logout',
      label: (
        <div
          className="dropdown-item"
          onClick={async () => {
            await axiosInstance.get(PathsAPI.logout);
            navigate(Paths.login);
          }}
        >
          <LogoutIcon className="logout-icon icon" />
          <span>Logout</span>
        </div>
      ),
    },
    {
      key: 'versions',
      label: (
        <div className="dropdown-item version-info">
          <div>
            <span>UI</span>
            <span>v{PackageJSON?.version}</span>
          </div>
          <div>
            <span>API</span>
            <span>v{mainState?.apiVersion}</span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.title = `${BRAND_NAME}${props.title ? ` - ${props.title}` : ''}`;
  }, []);

  return (
    <header>
      <div className="logo-name-wrapper">
        <a href={mainState?.prospect ? Paths.home : Paths.landing}>
          <LogoIcon className="logo-icon" />
        </a>
        <h6 className="name">
          {BRAND_NAME}
          <Tag className="beta-tag" color="processing">
            Beta
          </Tag>
        </h6>
      </div>
      {mainState?.prospect ? (
        <Dropdown menu={{ items }} placement="bottomRight" arrow>
          <button className="menu-button" onClick={async () => {}}>
            <MenuIcon className="menu-icon icon" />
          </button>
        </Dropdown>
      ) : (
        <>
          {isLoginPage ? (
            <button
              className="signup-button"
              onClick={() => {
                navigate(Paths.signup);
              }}
            >
              Sign up
            </button>
          ) : (
            <button
              className="login-button"
              onClick={() => {
                navigate(Paths.login);
              }}
            >
              Login
            </button>
          )}
        </>
      )}
    </header>
  );
};
