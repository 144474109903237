import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { Paths } from '../../routes/paths';
import { Header } from '../../components/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyEmail } from '../../actions/auth';
import { notification } from 'antd';
import { openNotificationWithIcon } from '../../components/notifications';

export const VerifyEmail = () => {
  const [api, contextHolder] = notification.useNotification();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (token) {
        try {
          await verifyEmail(token);
          setVerified(true);
        } catch (error) {
          openNotificationWithIcon({
            api,
            type: 'error',
            message: 'Invalid Token',
            description:
              'Unable to find token. If this is a mistake please contact support.',
          });
        }
      } else {
        navigate(Paths.forgotPassword);
      }
    };

    loadData();
  }, []);

  return (
    <section className="verify-email outer-wrapper">
      {contextHolder}
      <Header title="Verify Email" />
      <div className="center-wrapper">
        {verified ? (
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2>Email Verified</h2>
            </div>
            <div className="middle-wrapper">
              <p className="card-default">
                <CheckmarkIcon className="checkmark-icon icon" />
                Congratulations! You've successfully verified your email
                address.
              </p>
            </div>
            <div className="bottom-wrapper">
              <a href={Paths.home} className="action-button call-to-action">
                Home
              </a>
            </div>
          </div>
        ) : (
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2>Email Unverified</h2>
            </div>
            <div className="middle-wrapper">
              <p className="card-default">
                <CloseIcon className="close-icon icon" />
                Unable to verify email. If this is a mistake please contact
                support.
              </p>
            </div>
            <div className="bottom-wrapper">
              <a href={Paths.home} className="action-button call-to-action">
                Home
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
