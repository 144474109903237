import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import {
  CreateProspectReqBody,
  ProspectRO,
  UpdatePasswordReqBody,
  UpdateProspectReqBody,
} from '../typings/prospect';

// Get prospect
export const getProspect = async (): Promise<ProspectRO> => {
  return axiosInstance.get(PathsAPI.prospect);
};

// Create prospect
export const createProspect = async (
  body: CreateProspectReqBody,
): Promise<ProspectRO> => {
  return axiosInstance.post(PathsAPI.prospect, body);
};

// Update prospect
export const updateProspect = async (
  body: UpdateProspectReqBody,
): Promise<ProspectRO> => {
  return axiosInstance.put(PathsAPI.prospect, body);
};

// Delete prospect
export const deleteProspect = async (): Promise<ProspectRO> => {
  return axiosInstance.delete(PathsAPI.prospect);
};

// change password
export const changePassword = async (
  body: UpdatePasswordReqBody,
): Promise<ProspectRO> => {
  return axiosInstance.put(PathsAPI.changePassword, body);
};
