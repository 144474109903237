import React, { useEffect } from 'react';
import { Paths } from '../routes/paths';
import { GET_SITES } from '../actions';
import { getSites } from '../actions/site';
import { useMainContext } from '../context/context';
import { Header } from '../components/header';

export const Landing = () => {
  const [mainState, dispatch] = useMainContext();

  useEffect(() => {
    const loadData = async () => {
      const sites = await getSites({ perPage: 100, currentPage: 1 });
      const sitesActions = {
        type: GET_SITES,
        siteAction: {
          sites: sites.data,
        },
      };

      dispatch(sitesActions);
    };

    loadData();
  }, []);

  return (
    <section className="landing outer-wrapper">
      <Header title="Landing" />
      <div className="center-wrapper">
        <div className="inner-wrapper">
          <div className="above-fold">
            <h2>Get Instant Alerts For New Pets</h2>
            <p>
              Stay ahead and never miss your perfect pet! Our service tracks top
              market sites and sends you real-time alerts for new listings.
              Whether it's a rare morph or species, be the first to know. Sign
              up now and make your pet dreams come true!
            </p>
          </div>
          <div className="available-sites">
            <ul>
              {mainState?.sites?.data.map((site) => (
                <a href={site.url}>
                  <li key={`site-${site.id}`}>{site.name}</li>
                </a>
              ))}
            </ul>
          </div>
          <div className="actions-wrapper">
            <a
              href={Paths.signup}
              className="action-button signup call-to-action"
            >
              Sign Up
            </a>
            <a
              href={Paths.login}
              className="action-button login secondary-action"
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
