import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { Paths } from '../../routes/paths';
import { SUPPORT_EMAIL } from '../../shared/constants';
import { Header } from '../../components/header';

export const Privacy = () => {
  return (
    <section className="legal outer-wrapper">
      <Header title="Privacy Policy" />
      <div className="center-wrapper">
        <div className="inner-wrapper">
          <div className="top-wrapper">
            <h2>Privacy Policy</h2>
          </div>
          <div className="middle-wrapper">
            <p className="card-default">
              <b>1. Introduction</b>

              <span>
                This Privacy Policy outlines how [Website Name] (the "Service")
                collects, uses, and protects your personal information. By using
                the Service, you consent to the practices described in this
                policy.
              </span>

              <b>2. Information Collection</b>

              <span>
                The Service collects the following personal information:
              </span>

              <ul>
                <li>
                  Email Addresses: We collect email addresses when you sign up
                  for notifications or contact us.
                </li>
                <li>
                  IP Addresses: We automatically collect your IP address when
                  you visit the Service.
                </li>
                <li>
                  Phone Numbers: We may collect your phone number if you choose
                  to provide it.
                </li>
                <li>
                  Names: We may collect your name if you choose to provide it.
                </li>
              </ul>

              <b>3. Information Use</b>

              <span>
                We use your personal information for the following purposes:
              </span>

              <ul>
                <li>
                  To provide the Service: We use your information to deliver
                  notifications about animal availability.
                </li>
                <li>
                  To communicate with you: We may use your contact information
                  to respond to inquiries or provide updates.
                </li>
                <li>
                  For advertising purposes: We may use your IP address and other
                  information to deliver targeted advertisements.
                </li>
              </ul>

              <b>4. Information Sharing</b>

              <span>
                We may share your personal information with third-party service
                providers who assist us in operating the Service, such as:
              </span>

              <ul>
                <li>
                  Advertising partners: We may share your IP address and other
                  information with advertising networks to deliver targeted ads.
                </li>
                <li>
                  Email service providers: We may share your email address with
                  email service providers to send notifications.
                </li>
              </ul>

              <b>5. Data Security</b>

              <span>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction. However, no method of transmission
                over the internet or electronic storage is completely secure.
              </span>

              <b>6. Your Rights</b>

              <span>
                You may have the following rights regarding your personal
                information:
              </span>

              <ul>
                <li>
                  Access: You may request access to the personal information we
                  hold about you.
                </li>
                <li>
                  Rectification: You may request that we correct any inaccurate
                  personal information.
                </li>
                <li>
                  Erasure: You may request that we delete your personal
                  information under certain circumstances.
                </li>
                <li>
                  Restriction: You may request that we restrict the processing
                  of your personal information under certain circumstances.
                </li>
                <li>
                  Object: You may object to the processing of your personal
                  information for certain purposes.
                </li>
                <li>
                  Portability: You may request that we provide your personal
                  information in a structured, commonly used, and
                  machine-readable format.
                </li>
                <li>
                  To exercise your rights, please contact us at [Email Address]
                  or [Phone Number].
                </li>
              </ul>

              <b>7. Changes to this Policy</b>

              <span>
                We may update this Privacy Policy from time to time. We will
                notify you of any significant changes by posting a notice on the
                Service.
              </span>

              <b>8. Contact Information</b>

              <span>
                If you have any questions about this Privacy Policy or the
                Service, please contact us at <b>{SUPPORT_EMAIL}</b>.
              </span>
            </p>
          </div>
          <div className="bottom-wrapper">
            <a href={Paths.signup} className="action-button secondary-action">
              Home
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
