import React, { useEffect, useState } from 'react';
import { ConfigProvider, Input, notification, theme } from 'antd';
import { Header } from '../../components/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import { validatePassword } from '../../shared/validation';
import { openNotificationWithIcon } from '../../components/notifications';
import { resetPassword } from '../../actions/auth';

export const ResetPassword = () => {
  const [api, contextHolder] = notification.useNotification();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(Paths.forgotPassword);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <section className="reset-password outer-wrapper">
        {contextHolder}
        <Header title="Reset Password" />
        <div className="center-wrapper">
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2>Reset Password</h2>
            </div>
            <div className="middle-wrapper">
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Password"
              />
              <Input.Password
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                placeholder="Confirm Password"
              />
            </div>
            <div className="bottom-wrapper">
              <button
                className="action-button call-to-action"
                onClick={async () => {
                  if (!token) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Invalid Token',
                      description: 'Unable to find token. Please try again.',
                    });

                    navigate(Paths.forgotPassword);

                    return;
                  }

                  if (password !== confirmPassword) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Passwords do not match',
                      description: 'Please make sure your passwords match.',
                    });

                    return;
                  }

                  if (!validatePassword(password)) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Invalid Password',
                      description:
                        'Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.',
                    });

                    return;
                  }

                  await resetPassword({
                    token,
                    confirmPassword,
                    newPassword: password,
                  });
                  navigate(Paths.home);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
};
