import { Setting } from 'species-finder-db/src/models/setting';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { ProspectRO } from '../typings/prospect';

export interface CheckResponse {
  prospect: ProspectRO;
  setting: Setting;
  accessToken: string;
  apiVersion: string;
}

export const authRefresh = async (): Promise<CheckResponse | undefined> => {
  try {
    const response = await axiosInstance.get(PathsAPI.check);
    return {
      prospect: response.data.prospect,
      setting: response.data.setting,
      accessToken: response.data.accessToken,
      apiVersion: response.data.version,
    };
  } catch (error) {
    // do nothing
  }
};
