import { NotificationInstance } from 'antd/es/notification/interface';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotificationWithIcon = (props: {
  api: NotificationInstance;
  type: NotificationType;
  message: string;
  description: string;
}) => {
  const { api, type, message, description } = props;

  api[type]({
    message,
    description,
  });
};
