import { Setting } from 'species-finder-db/src/models/setting';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { UpdateSettingReqBody } from '../typings/setting';

// get setting
export const getSetting = async (): Promise<Setting> => {
  return axiosInstance.get(PathsAPI.setting);
};

// update setting
export const updateSetting = async (
  body: UpdateSettingReqBody,
): Promise<Setting> => {
  return axiosInstance.put(PathsAPI.setting, body);
};
