/* istanbul ignore file */
export enum PathsAPI {
  // Auth
  login = 'api/auth/login',
  logout = 'api/auth/logout',
  refresh = 'api/auth/refresh',
  forgotPassword = 'api/auth/forgot_password',
  passwordReset = 'api/auth/reset_password',
  sendVerifyEmail = 'api/auth/send_verify_email',
  verifyEmail = 'api/auth/verify_email',
  verifyEmailIsAvailable = 'api/verify_email_is_available',
  interest = 'api/interest',
  prospect = 'api/prospect',
  setting = 'api/setting',
  site = 'api/site',
  specimen = 'api/specimen',
  changePassword = 'api/prospect/change_password',
  check = '/api/auth/refresh',
}

export enum Paths {
  // Auth
  login = '/login',
  signup = '/sign-up',
  forgotPassword = '/forgot-password',
  passwordReset = '/reset-password',
  verifyEmail = '/verify-email',
  logout = '/logout',

  // Base
  home = '/',
  landing = '/landing',
  settings = '/settings',

  // Legal
  terms = '/terms',
  privacy = '/privacy',
}
