import React, { useEffect, useState } from 'react';
import { ConfigProvider, Input, notification, theme } from 'antd';
import { Paths, PathsAPI } from '../../routes/paths';
import { Header } from '../../components/header';
import { authRefresh } from '../../actions/check';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from '../../context/context';
import { axiosInstance } from '../../shared/axios';
import { LOGIN } from '../../actions';
import { MainAction } from '../../typings/main_context_reducer';
import { openNotificationWithIcon } from '../../components/notifications';

export const Login = () => {
  const [, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const check = await authRefresh();

      if (check) {
        navigate(Paths.home);
      }
    };

    loadData();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <section className="login outer-wrapper">
        {contextHolder}
        <Header title="Login" />
        <div className="center-wrapper">
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2>Login</h2>
            </div>
            <div className="middle-wrapper">
              <Input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
              />
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Password"
              />
              <p className="card-default">
                <a href={Paths.forgotPassword}>Forgot Password?</a>
              </p>
            </div>
            <div className="bottom-wrapper">
              <button
                className="action-button submit-btn call-to-action"
                onClick={async () => {
                  try {
                    const responseLogin = await axiosInstance.post(
                      PathsAPI.login,
                      {
                        email,
                        password,
                      },
                    );

                    dispatch({
                      type: LOGIN,
                      LoginAction: {
                        account: responseLogin.data.account,
                      },
                    } as MainAction);
                    navigate(Paths.home);
                  } catch (error) {
                    openNotificationWithIcon({
                      api,
                      type: 'error',
                      message: 'Error',
                      description:
                        'Either your email or password is incorrect.',
                    });

                    console.error(error);
                  }
                }}
              >
                Submit
              </button>
              <p className="card-default">
                Don't have an account? <a href={Paths.signup}>Sign up</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
};
