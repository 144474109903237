import { Interest } from 'species-finder-db/src/models/interest';
import { ListOutput } from '../typings/general';
import { CreateInterestReqBody, FetchInterestQuery } from '../typings/interest';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { AxiosResponse } from 'axios';

// Get interests
export const getInterests = async (
  query?: FetchInterestQuery,
): Promise<AxiosResponse<ListOutput<Interest>>> => {
  return axiosInstance.get(PathsAPI.interest, { params: query });
};

// Get interest
export const getInterest = async (
  id: string,
): Promise<AxiosResponse<Interest>> => {
  return axiosInstance.get(`${PathsAPI.interest}/${id}`);
};

// Create interest
export const createInterest = async (
  body: CreateInterestReqBody,
): Promise<AxiosResponse<Interest>> => {
  return axiosInstance.post(PathsAPI.interest, body);
};

// Delete interest
export const deleteInterest = async (id: string): Promise<void> => {
  return axiosInstance.delete(`${PathsAPI.interest}/${id}`);
};
