import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { LoginReqBody } from '../typings/auth';

export const forgotPassword = async (email: string): Promise<void> => {
  try {
    await axiosInstance.post(PathsAPI.forgotPassword, { email });
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (body: {
  token: string;
  newPassword: string;
  confirmPassword: string;
}): Promise<void> => {
  return axiosInstance.post(PathsAPI.passwordReset, body);
};

export const login = async (body: LoginReqBody): Promise<void> => {
  return axiosInstance.post(PathsAPI.login, body);
};

export const logout = async (): Promise<void> => {
  return axiosInstance.get(PathsAPI.logout);
};

export const refresh = async (): Promise<void> => {
  return axiosInstance.get(PathsAPI.refresh);
};

export const sendVerifyEmail = async (): Promise<void> => {
  return axiosInstance.post(PathsAPI.sendVerifyEmail);
};

export const verifyEmail = async (token: string): Promise<void> => {
  return axiosInstance.post(PathsAPI.verifyEmail, { token });
};
