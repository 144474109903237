import { Site } from 'species-finder-db/src/models/site';
import { ListOutput, QueryDTO } from '../typings/general';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { AxiosResponse } from 'axios';

// get sites
export const getSites = async (
  query?: QueryDTO,
): Promise<AxiosResponse<ListOutput<Site>>> => {
  return axiosInstance.get(PathsAPI.site, { params: query });
};

// get site
export const getSite = async (id: string): Promise<Site> => {
  return axiosInstance.get(`${PathsAPI.site}/${id}`);
};
