export const extractQueryParams = (url: string): { [key: string]: string } => {
  const urlObj = new URL(url);
  const searchParams: { [key: string]: string } = {};

  urlObj.searchParams.forEach((val, key) => {
    searchParams[key] = val;
  });

  return searchParams;
};
