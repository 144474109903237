import moment from 'moment';

export const formateDateTimeForAPI = (inputDateTime: Date) => {
  const userTimezoneOffset = inputDateTime.getTimezoneOffset() * 60000;

  return new Date(inputDateTime.getTime() - userTimezoneOffset).toISOString();
};

export const formateDateStandard = (dateString: string | undefined) => {
  return moment(dateString).format('LL');
};

export const formateDateSimple = (
  dateString: string | undefined,
  endString = 'ago',
  limit?: boolean,
): string => {
  const inputDate = moment(dateString);
  const currentDate = moment();
  const secondsSince = currentDate.diff(inputDate, 'seconds');
  const minutesSince = currentDate.diff(inputDate, 'minute');
  const hoursSince = currentDate.diff(inputDate, 'hour');
  const daysSince = currentDate.diff(inputDate, 'day');
  const weeksSince = currentDate.diff(inputDate, 'week');
  const monthSince = currentDate.diff(inputDate, 'month');
  const yearSince = currentDate.diff(inputDate, 'year');

  const minutesInHour = 60;
  const hoursInDay = 24;
  const daysInWeek = 7;
  const weeksInMonth = 4;
  const monthsInYear = 12;

  if (minutesSince < minutesInHour && minutesSince === 0) {
    return `${secondsSince} second${secondsSince > 1 ? 's' : ''} ${endString}`;
  }

  if (minutesSince < minutesInHour) {
    return `${minutesSince} minute${minutesSince > 1 ? 's' : ''} ${endString}`;
  }

  if (hoursSince < hoursInDay) {
    return `${hoursSince} hour${hoursSince > 1 ? 's' : ''} ${endString}`;
  }

  if (daysSince < daysInWeek) {
    return `${daysSince} day${daysSince > 1 ? 's' : ''} ${endString}`;
  }

  if (limit) {
    return inputDate.format('MMM DD, YY HH:mm a');
  }

  if (
    weeksSince < weeksInMonth ||
    (weeksSince === weeksInMonth && monthSince < monthsInYear)
  ) {
    return `${weeksSince} week${weeksSince > 1 ? 's' : ''} ${endString}`;
  }

  if (monthSince < monthsInYear) {
    return `${monthSince} month${monthSince > 1 ? 's' : ''} ${endString}`;
  }

  return `${yearSince} year${yearSince > 1 ? 's' : ''} ${endString}`;
};
