import React, { useEffect, useRef, useState } from 'react';
import {
  Select,
  Input,
  notification,
  FloatButton,
  ConfigProvider,
  theme,
  GetProps,
  Modal,
} from 'antd';

import { Header } from '../components/header';
import { useMainContext } from '../context/context';
import { useNavigate } from 'react-router-dom';
import { authRefresh } from '../actions/check';
import { Paths } from '../routes/paths';
import { CHECK } from '../actions';
import { MainAction } from '../typings/main_context_reducer';
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as FilterIcon } from '../icons/filter.svg';
import { ReactComponent as FeedbackIcon } from '../icons/feedback.svg';
import { Interest } from 'species-finder-db/src/models/interest';
import { InterestCard, MatchesCard } from '../components/cards';
import { SpecimenResBody } from '../typings/specimen';
import {
  createInterest,
  deleteInterest,
  getInterests,
} from '../actions/interest';
import { getSpecimens } from '../actions/specimen';
import { validateInterestName } from '../shared/validation';
import { openNotificationWithIcon } from '../components/notifications';
import { DEFAULT_ERROR_DESCRIPTION } from '../shared/constants';
import { getSites } from '../actions/site';
import { SelectOption } from '../typings/general';

const { Search } = Input;
const { TextArea } = Input;
type SearchProps = GetProps<typeof Input.Search>;

export const Home = () => {
  const [, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const homeCenterRef = useRef<HTMLDivElement>(null);
  const matchesRef = useRef<HTMLDivElement>(null);

  const [hasInit, setHasInit] = useState(false);
  const [newInterest, setNewInterest] = useState('');
  const [showAddInterest, setShowAddInterest] = useState(false);
  const [showMatchesFilter, setShowMatchesFilter] = useState(false);
  const [interests, setInterests] = useState<Interest[]>([]);
  const [specimens, setSpecimens] = useState<SpecimenResBody[]>([]);
  const [matchesFilterOpts, setMatchesFilterOpts] = useState<SelectOption[]>([
    { value: '', label: 'All Interests' },
  ]);
  const [sites, setSites] = useState<SelectOption[]>([
    {
      value: '',
      label: 'All Sites',
    },
  ]);
  const [matchesFilterValue, setMatchesFilterValue] = useState<string>(
    matchesFilterOpts[0].value,
  );
  const [refreshSpecimens, setRefreshSpecimens] = useState(false);
  const [nextSpecimenPage, setNextSpecimenPage] = useState('');
  const [reachedEndSpecimens, setReachedEndSpecimens] = useState(false);
  const [selectedSite, setSelectedSite] = useState<string>(sites[0].value);
  const [title, setTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();

        if (check === undefined) {
          navigate(Paths.login);
        }

        if (!check?.prospect.is_verified) {
          openNotificationWithIcon({
            api,
            type: 'info',
            message: 'Email verification',
            description:
              'Please verify your email. A verification link has been sent to your email.',
          });
        }

        const interests = await getInterests();
        const specimens = await getSpecimens({ sort_by: '-id' });
        const sitesFetched = await getSites({ perPage: 100 });

        const checkAction = {
          type: CHECK,
          checkAction: check,
          interestAction: {
            interests,
          },
          specimenAction: {
            specimens,
          },
        } as MainAction;

        dispatch(checkAction);
        setInterests(interests.data.data);
        setSpecimens(specimens.data.data);
        setSites([
          ...sites,
          ...sitesFetched.data.data.map((site) => ({
            value: site.id,
            label: site.name,
          })),
        ]);
        setNextSpecimenPage(specimens.data.nextPage);
        setReachedEndSpecimens(!specimens.data.nextPage);
        setMatchesFilterOpts([
          ...matchesFilterOpts,
          ...interests.data.data.map((i) => ({ value: i.id, label: i.name })),
        ]);
        setHasInit(true);
      } catch (error) {
        console.error(error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const specimens = await getSpecimens({
        sort_by: '-id',
        interest_id: matchesFilterValue || undefined,
        is_sold_out: false,
        site_id: selectedSite || undefined,
        title: title,
      });

      setSpecimens(specimens.data.data);
      setNextSpecimenPage(specimens.data.nextPage);
      setReachedEndSpecimens(!specimens.data.nextPage);
    };

    if (hasInit) {
      loadData();
    }
  }, [matchesFilterValue, selectedSite, title]);

  useEffect(() => {
    const loadData = async () => {
      const fetchedSpecimens = await getSpecimens(
        {
          sort_by: '-id',
          interest_id: matchesFilterValue || undefined,
          is_sold_out: false,
          site_id: selectedSite || undefined,
          title: title,
        },
        nextSpecimenPage.length > 0 ? nextSpecimenPage : undefined,
      );

      setSpecimens([...specimens, ...fetchedSpecimens.data.data]);
      setNextSpecimenPage(fetchedSpecimens.data.nextPage);
      setReachedEndSpecimens(!fetchedSpecimens.data.nextPage);
      setRefreshSpecimens(false);
    };

    if (refreshSpecimens && !reachedEndSpecimens) {
      loadData();
    }
  }, [refreshSpecimens]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setRefreshSpecimens(true);
    }
  };

  const addInterest = async () => {
    const isValid = validateInterestName(newInterest);

    if (!isValid) {
      openNotificationWithIcon({
        api,
        type: 'error',
        message: 'Error',
        description:
          'Name should only contain letters, numbers, spaces, dashes, underscores, and between 3 and 100 characters in length.',
      });
      return;
    }

    try {
      const createdInterest = await createInterest({
        name: newInterest,
      });
      setInterests([createdInterest.data, ...interests]);

      setNewInterest('');
      setRefreshSpecimens(true);

      setMatchesFilterOpts([
        ...matchesFilterOpts,
        {
          value: createdInterest.data.id,
          label: createdInterest.data.name,
        },
      ]);

      openNotificationWithIcon({
        api,
        type: 'success',
        message: 'Success',
        description: 'Successfully added interest.',
      });
    } catch (error: any) {
      openNotificationWithIcon({
        api,
        type: 'error',
        message: 'Error',
        description:
          error?.response?.data?.message ?? DEFAULT_ERROR_DESCRIPTION,
      });
    }
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setTitle(value);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      >
        <section
          className="home outer-wrapper"
          onScroll={(e: any) => {
            handleScroll(e);
          }}
          ref={homeCenterRef}
        >
          {contextHolder}
          <Header title="Home" />
          <div className="center-wrapper">
            <div className="inner-wrapper">
              <div className="middle-wrapper">
                <div className="cards-wrapper">
                  <div className="title-row">
                    <h5>Interests</h5>
                    {!showAddInterest && (
                      <button
                        className="add-btn"
                        onClick={() => setShowAddInterest(true)}
                      >
                        <AddIcon className="add-icon icon" />
                      </button>
                    )}

                    {showAddInterest && (
                      <button
                        className="close-btn"
                        onClick={() => {
                          setShowAddInterest(false);
                          setNewInterest('');
                        }}
                      >
                        <CloseIcon className="close-icon icon" />
                      </button>
                    )}
                  </div>
                  {showAddInterest && (
                    <div className="input-wrapper">
                      <Input
                        type="text"
                        onChange={(e) => setNewInterest(e.target.value)}
                        value={newInterest}
                        placeholder="Name"
                        onKeyDown={async (e) => {
                          if (e.key === 'Enter') {
                            await addInterest();
                          }
                        }}
                      />
                      <button className="add-btn" onClick={addInterest}>
                        <AddIcon className="add-icon icon" />
                      </button>
                    </div>
                  )}
                  {interests.length === 0 ? (
                    <div className="no-data card-default">Empty</div>
                  ) : (
                    <>
                      <ul>
                        {interests.map((interest) => (
                          <InterestCard
                            key={interest.id}
                            name={interest.name}
                            id={interest.id}
                            handleClick={() => {
                              matchesRef?.current?.scrollIntoView();
                              setMatchesFilterValue(interest.id);
                            }}
                            handleDelete={async () => {
                              await deleteInterest(interest.id);
                              setInterests(
                                interests.filter((i) => i.id !== interest.id),
                              );
                            }}
                          />
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <div ref={matchesRef} className="cards-wrapper matches-wrapper">
                  <div className="title-row">
                    <h5>Matches</h5>

                    {!showMatchesFilter && (
                      <button
                        className="filter-btn"
                        onClick={() => setShowMatchesFilter(true)}
                      >
                        <FilterIcon className="filter-icon icon" />
                      </button>
                    )}

                    {showMatchesFilter && (
                      <button
                        className="close-btn"
                        onClick={() => {
                          setShowMatchesFilter(false);
                        }}
                      >
                        <CloseIcon className="close-icon icon" />
                      </button>
                    )}
                  </div>
                  {specimens.length === 0 &&
                  matchesFilterValue === matchesFilterOpts[0].value &&
                  selectedSite === sites[0].value ? (
                    <div className="no-data card-default">Empty</div>
                  ) : (
                    <>
                      {showMatchesFilter && (
                        <>
                          <Select
                            value={matchesFilterValue}
                            onChange={(val) => setMatchesFilterValue(val)}
                            options={matchesFilterOpts}
                          />
                          <Select
                            value={selectedSite}
                            onChange={(val) => setSelectedSite(val)}
                            options={sites}
                          />
                          <Search
                            placeholder="Name"
                            allowClear
                            enterButton="Search"
                            onSearch={onSearch}
                          />
                        </>
                      )}
                      <ul>
                        {specimens.map((specimen) => (
                          <MatchesCard
                            key={specimen.id}
                            name={specimen.title}
                            discoveredOn={specimen.created_on}
                            siteName={specimen.site_name}
                            url={specimen.url}
                            imageSrc={specimen.imgs}
                            regularPrice={specimen.regular_price}
                            salesPrice={specimen.sale_price}
                          />
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <FloatButton.BackTop
          target={() => homeCenterRef.current as HTMLElement}
          className="back-top-float-btn"
        />
        <FloatButton
          className="feedback-float-btn"
          icon={<FeedbackIcon className="feedback-icon icon" />}
          type="default"
          style={{ insetInlineEnd: 94 }}
          onClick={() => setIsModalOpen(true)}
        />
        <Modal
          title="Feedback"
          open={isModalOpen}
          onOk={() => {
            setIsModalOpen(false);
          }}
          onCancel={() => setIsModalOpen(false)}
          className="feedback-modal"
          okText="Send"
        >
          <TextArea
            value={feedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
            className="feedback-textarea"
          />
        </Modal>
      </ConfigProvider>
    </>
  );
};
