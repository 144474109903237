import { ListOutput } from '../typings/general';
import { FetchSpecimenQuery, SpecimenResBody } from '../typings/specimen';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { AxiosResponse } from 'axios';
import { extractQueryParams } from '../utils/url';

// get specimens
export const getSpecimens = async (
  query?: FetchSpecimenQuery,
  nextPage?: string,
): Promise<AxiosResponse<ListOutput<SpecimenResBody>>> => {
  if (nextPage) {
    const extractedQuery = extractQueryParams(nextPage);

    return axiosInstance.get(PathsAPI.specimen, {
      params: { ...extractedQuery, ...query },
    });
  }
  return axiosInstance.get(PathsAPI.specimen, { params: query });
};

// get specimen
export const getSpecimen = async (
  id: string,
): Promise<AxiosResponse<SpecimenResBody>> => {
  return axiosInstance.get(`${PathsAPI.specimen}/${id}`);
};
